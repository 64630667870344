import { useReducer, useEffect } from 'react';
import throttle from 'lodash/throttle';

export enum Direction {
  Up = 'UP',
  Down = 'DOWN',
}

enum ActionType {
  SetScroll = 'SET_SCROLL',
}

interface State {
  position: number;
  direction: Direction | null;
  scrollAtTop: boolean;
  scrollAtBottom: boolean;
}

interface Action {
  type: ActionType.SetScroll;
  position: number;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SetScroll:
      const direction =
        action.position > state.position ? Direction.Down : Direction.Up;
      const scrollAtTop = action.position === 0;
      const scrollAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      return {
        ...state,
        position: action.position,
        scrollAtTop,
        scrollAtBottom,
        direction,
      };
  }
};

const initialState: State = {
  position: 0,
  direction: null,
  scrollAtTop: true,
  scrollAtBottom: false,
};

export const useScrollPosition = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleScroll = throttle(() => {
    const newPosition =
      window?.pageYOffset || window?.document?.documentElement?.scrollTop || 0;
    dispatch({ type: ActionType.SetScroll, position: newPosition });
  }, 150);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return { ...state };
};
