import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { Link } from '../components/Link';
import Language from '../constants/Language';
import { format } from 'date-fns';
import algoliasearch from 'algoliasearch';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
  useInstantSearch,
} from 'react-instantsearch';
import { Hit } from 'instantsearch.js';

import 'instantsearch.css/themes/satellite.css';
import Button from './base/Button';
import debounce from 'lodash/debounce';
import { MultipleQueriesResponse } from '@algolia/client-search';
import { useUI } from 'providers/UIProvider';

interface Props {}

export type HitProps = Hit & {
  objectID: string;
  type: string;
  title: string;
  slug: string;
  section?: {
    title: string;
    slug: string;
  };
  authors?: {
    firstName: string;
    lastName: string;
  }[];
  podcast?: {
    title: string;
    slug: string;
  };
  releaseDate?: string;
  createdAt?: string;
  updatedAt?: string;
  showType?: boolean;
};

export const SearchBar: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const hitsPerPage = 20;
  const resultsRef = useRef<HTMLDivElement>(null);

  const { searchIsOpen, closeSearchBar, unlockScroll } = useUI();

  const algoliaApplicationId = process.env
    .NEXT_PUBLIC_ALGOLIA_APPLICATION_ID as string;
  const algoliaSearchKey = process.env
    .NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY as string;
  const algoliaIndexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME as string;

  const algoliaClient = algoliasearch(algoliaApplicationId, algoliaSearchKey);

  const searchClient = {
    ...algoliaClient,
    search: (
      requests: any
    ): Readonly<Promise<MultipleQueriesResponse<any>>> => {
      if (requests.every(({ params }: any) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        });
      }

      return algoliaClient.search(requests);
    },
  };

  const determineResultItemType = (hitType: string) => {
    switch (hitType) {
      case 'recipeArticle':
        return 'recipe';
      case 'podcastEpisode':
        return 'podcast episode';
      case 'featureArticlePage':
        return 'feature article';
      default:
        return hitType;
    }
  };

  const generateResultItemLink = (algoliaHit: HitProps) => {
    switch (algoliaHit.type) {
      case 'article':
        return (
          <>
            <Link
              to={`/sections/${algoliaHit.section?.slug}/articles/${algoliaHit.slug}`}
            >
              <span className="text-article-details-sm itc-cushing color-black font-600">
                {algoliaHit.title}
              </span>
            </Link>
            {algoliaHit.authors && algoliaHit.authors.length > 0 && (
              <div
                className={cx(
                  'AuthorButtons text-global-details-xxs itc-cushing color-black font-400 uppercase'
                )}
              >
                <span className="pr_25 byline">
                  {Language.t('Global.byline')}
                </span>
                {algoliaHit.authors.map(
                  (
                    author: { firstName: string; lastName: string },
                    i: number
                  ) => {
                    const name = `${author.firstName} ${author.lastName}`;
                    return (
                      <span key={algoliaHit.objectID}>
                        {name}
                        {i !== (algoliaHit.authors?.length || 0) - 1 &&
                          (algoliaHit.authors?.length || 0) !== 2 && (
                            <span className="pr_25 color-black">
                              {Language.t('Global.comma')}
                            </span>
                          )}
                        {i !== (algoliaHit.authors?.length || 0) - 1 &&
                          i === (algoliaHit.authors?.length || 0) - 2 && (
                            <span className="pl_25 pr_25">
                              {Language.t('Global.and')}
                            </span>
                          )}
                      </span>
                    );
                  }
                )}
              </div>
            )}
            {algoliaHit.releaseDate ? (
              <div>
                <span className="text-global-details-xxs itc-cushing color-black font-400 uppercase">
                  {format(new Date(algoliaHit.releaseDate), 'MMMM dd, y')}
                </span>
              </div>
            ) : algoliaHit.createdAt ? (
              <div>
                <span className="text-global-details-xxs itc-cushing color-black font-400 uppercase">
                  {format(new Date(algoliaHit.createdAt), 'MMMM dd, y')}
                </span>
              </div>
            ) : (
              ''
            )}
          </>
        );
      case 'collection':
        return (
          <Link to={`/collections/${algoliaHit.slug}`}>
            <span className="text-article-details-sm itc-cushing color-black font-600">
              {algoliaHit.title}
            </span>
          </Link>
        );
      case 'column':
        return (
          <Link to={`/columns/${algoliaHit.slug}`}>
            <span className="text-article-details-sm itc-cushing color-black font-600">
              {algoliaHit.title}
            </span>
          </Link>
        );
      case 'featureArticlePage':
        return (
          <>
            <Link to={`/feature/${algoliaHit.slug}`}>
              <span className="text-article-details-sm itc-cushing color-black font-600">
                {algoliaHit.title}
              </span>
            </Link>
            {algoliaHit.authors && algoliaHit.authors.length > 0 && (
              <div
                className={cx(
                  'AuthorButtons text-global-details-xxs itc-cushing color-black font-400 uppercase'
                )}
              >
                <span className="pr_25 byline">
                  {Language.t('Global.byline')}
                </span>
                {algoliaHit.authors.map(
                  (
                    author: { firstName: string; lastName: string },
                    i: number
                  ) => {
                    const name = `${author.firstName} ${author.lastName}`;
                    return (
                      <span key={algoliaHit.objectID}>
                        {name}
                        {i !== (algoliaHit.authors?.length || 0) - 1 &&
                          (algoliaHit.authors?.length || 0) !== 2 && (
                            <span className="pr_25 color-black">
                              {Language.t('Global.comma')}
                            </span>
                          )}
                        {i !== (algoliaHit.authors?.length || 0) - 1 &&
                          i === (algoliaHit.authors?.length || 0) - 2 && (
                            <span className="pl_25 pr_25">
                              {Language.t('Global.and')}
                            </span>
                          )}
                      </span>
                    );
                  }
                )}
              </div>
            )}
            {algoliaHit.createdAt ? (
              <div>
                <span className="text-global-details-xxs itc-cushing color-black font-400 uppercase">
                  {format(new Date(algoliaHit.createdAt), 'MMMM dd, y')}
                </span>
              </div>
            ) : (
              ''
            )}
          </>
        );
      case 'holiday':
        return (
          <Link to={`/sections/holidays/${algoliaHit.slug}`}>
            <span className="text-article-details-sm itc-cushing color-black font-600">
              {algoliaHit.title}
            </span>
          </Link>
        );
      case 'podcast':
        return (
          <Link to={`/podcasts/${algoliaHit.slug}`}>
            <span className="text-article-details-sm itc-cushing color-black font-600">
              {algoliaHit.title}
            </span>
          </Link>
        );
      case 'podcastEpisode':
        return (
          <Link to={`/podcasts/${algoliaHit.podcast?.slug}/${algoliaHit.slug}`}>
            <span className="text-article-details-sm itc-cushing color-black font-600">
              {algoliaHit.title}
            </span>
          </Link>
        );
      case 'recipeArticle':
        return (
          <Link to={`/recipes/${algoliaHit.slug}`}>
            <span className="text-article-details-sm itc-cushing color-black font-600">
              {algoliaHit.title}
            </span>
          </Link>
        );
      case 'tag':
        return (
          <Link to={`/tags/${algoliaHit.slug}`}>
            <span className="text-article-details-sm itc-cushing color-black font-600">
              {algoliaHit.title}
            </span>
          </Link>
        );
      default:
        return (
          <span className="text-article-details-sm itc-cushing color-black font-600">
            {algoliaHit.title}
          </span>
        );
    }
  };

  const ResultItem: React.FC<any> = ({ hit, sendEvent }) => {
    const resultItemType = determineResultItemType(hit.type);

    return (
      <div className="SearchBar__result-item flex flex-col">
        {/* {hit.showType && ( */}
        <span className="text-global-details-xxs graebenbach color-black font-400">
          {resultItemType.toUpperCase()}
        </span>
        {/* )} */}
        <div
          onClick={() => {
            sendEvent('click', hit, `${resultItemType} clicked`);
            closeSearchBar();
            unlockScroll();
          }}
        >
          {generateResultItemLink(hit)}
        </div>
      </div>
    );
  };

  // The groupHitsByType below is used to group the hits by type
  // const groupHitsByType = (hits: HitProps[]) => {
  //   const groupedHits: { [key: string]: HitProps[] } = {};
  //   const groupedType: string[] = [];

  //   hits.forEach((hit) => {
  //     const resultItemType: string = hit.type;
  //     if (!groupedHits[resultItemType]) {
  //       groupedHits[resultItemType] = [];
  //     }

  //     if (!groupedType.includes(resultItemType)) {
  //       groupedType.push(resultItemType);
  //       hit.showType = true;
  //     }

  //     groupedHits[resultItemType].push(hit);
  //   });

  //   return groupedHits;
  // };

  const EmptyQueryBoundary = ({ children }: any) => {
    const { indexUiState } = useInstantSearch();

    // Return null if query is blank or less than 3 characters
    if (!indexUiState.query || indexUiState.query.trim().length < 3) {
      return null;
    }

    return children;
  };

  const searchQueryHook = (query: string, search: (value: string) => void) => {
    //Only search if query is not blank or less than 3 characters
    if (query && query.trim().length >= 3) {
      search(query);
    }
  };

  const searchQueryDebounced = debounce(searchQueryHook, 300);

  const RenderScrollDownMessage: React.FC<any> = () => {
    const { results } = useInstantSearch();

    if (results.hits.length > 4) {
      return (
        <Button
          ariaLabel={Language.t('SideNav.MainMenu.searchScrollAriaLabel')}
          className={cx(
            'SearchBar__down-button z-search h100 bg-color-transparent text-global-details-xxs itc-cushing font-600 mxauto'
          )}
        >
          <span>{Language.t('SideNav.MainMenu.searchScrollAriaLabel')}</span>
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className={cx(
        'SearchBar absolute z-search t0 l0 r0 mxauto flex-col items-center justify-center w100 bg-color-gray-lighter',
        {
          'flex transition-open-search': searchIsOpen,
          'transition-close-search': !searchIsOpen,
        }
      )}
    >
      <div className="SearchBar__content-container mxauto w100 absolute flex flex-col items-center bg-color-gray-lighter">
        <div className="SearchBar__heading content-padding-x itc-cushing mxauto color-black">
          Search
        </div>
        <div
          className={cx(
            'SearchBar__search-container mxauto w100 flex flex-col items-center space-between'
          )}
        >
          <InstantSearch
            indexName={algoliaIndexName}
            searchClient={searchClient}
            insights={true}
          >
            <SearchBox
              className="SearchBar__searchbox w100 mxauto"
              placeholder="What are you looking for?"
              searchAsYouType={true}
              queryHook={searchQueryDebounced}
            />
            <Configure hitsPerPage={hitsPerPage} page={page} />
            <EmptyQueryBoundary>
              <Hits
                ref={resultsRef}
                className={cx(
                  'SearchBar__hits mxauto z-search w100 h100 bg-color-gray-lighter li-color-gray-lighter'
                )}
                hitComponent={ResultItem}
                // The transformItems attribute below is used to group the hits by type
                // transformItems={(items: HitProps[] | undefined) => {
                //   if (!items) {
                //     return [];
                //   }
                //   const groupedHits = groupHitsByType(items);
                //   const flattenedHits: HitProps[] =
                //     Object.values(groupedHits).flat();
                //   return flattenedHits;
                // }}
              />
              <RenderScrollDownMessage />
            </EmptyQueryBoundary>
          </InstantSearch>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
