import React from 'react';
import { Link } from '../components/Link';
import cx from 'classnames';

import { Button, Img } from '../components/base';
import { MenuIcon, TabletLogo } from '../components/icons';
import { Theme } from '../types';
import { RouteMap } from '../constants/RouteMap';
import Language from '../constants/Language';
import { useUI } from '../providers/UIProvider';
import { useScrollPosition } from '../utils/useScrollPosition';
import { useIsFiction } from '../hooks/useFiction';

import ScrollLock from './ScrollLock';
import SearchBar from './SearchBar';
import { useSiteSettings } from '../providers/SiteSettingsProvider';

interface Props {
  children?: React.ReactNode;
  showMiddleLogo?: boolean;
  className?: string;
  isFixed?: boolean;
  hideNav?: boolean;
  style?: React.CSSProperties;
  hideLogoOnTop?: boolean;
  isHomeNav?: boolean;
  hideLogo?: boolean;
  isCollectionsNav?: boolean;
  showAppButton?: boolean;
}

export const Nav: React.FC<Props> = (props) => {
  const { position: scrollPosition } = useScrollPosition();
  const isFiction = useIsFiction();
  const {
    theme,
    sideNavIsOpen,
    sectionNavIsOpen,
    openSideNav,
    closeSideNav,
    searchIsOpen,
    openSearchBar,
    closeSearchBar,
    lockScroll,
    unlockScroll,
  } = useUI();

  const {
    children,
    showMiddleLogo,
    className = '',
    isFixed = true,
    hideNav,
    style,
    hideLogoOnTop,
    hideLogo,
    isHomeNav,
    isCollectionsNav,
    showAppButton,
  } = props;

  const { globalSettings } = useSiteSettings();
  const tabletAppButtonText =
    globalSettings?.tabletAppButtonText || 'Get The App';
  const { tabletAppLink } = globalSettings || {};

  const showTabletAppButton =
    !searchIsOpen && showAppButton && tabletAppButtonText && tabletAppLink;

  const handleSideNavClick = () => {
    if (!sideNavIsOpen) {
      openSideNav();
    } else {
      closeSideNav();
    }
  };

  const handleSearchClick = () => {
    if (!searchIsOpen) {
      openSearchBar();
      lockScroll();
    } else {
      closeSearchBar();
      unlockScroll();
    }
  };

  const iconColor = isFiction
    ? 'fiction-blue'
    : theme === Theme.Black && !searchIsOpen
    ? 'white'
    : 'black';

  return (
    <>
      <div
        style={style}
        className={cx(
          'Nav t0 l0 r0 mxauto flex justify-center md:hauto events-none no-display-in-print',
          {
            'opacity-0': sectionNavIsOpen,
            fixed: isFixed,
            absolute: !isFixed,
            'Nav--side-nav-is-active': isFixed && sideNavIsOpen,
            'bg-color-off-white':
              (!hideLogo &&
                !isHomeNav &&
                theme === Theme.Default &&
                !searchIsOpen) ||
              (isHomeNav &&
                theme === Theme.Default &&
                scrollPosition > 0 &&
                !searchIsOpen),
            'bg-color-beige':
              !isHomeNav && theme === Theme.Beige && !hideLogo && !searchIsOpen,
            'bg-color-black':
              !isHomeNav && theme === Theme.Black && !hideLogo && !searchIsOpen,
            'bg-color-transparent': hideLogo,
            'bg-color-collections': isCollectionsNav && !searchIsOpen,
            'bg-color-gray-lighter z-nav': searchIsOpen,
            'z-sidebar': !searchIsOpen,
          }
        )}
      >
        <div className="inner-content-max-width content-padding-x mxauto flex flex-col items-center justify-center w100 h100 absolute">
          <div
            className={cx(
              'inner-content-max-width mxauto content-padding-x w100 h100 py_25 absolute flex justify-between items-center'
            )}
          >
            <div className="z-nav">
              <Button
                ariaLabel={Language.t('Global.openMenuAriaLabel')}
                onClick={handleSideNavClick}
                className={cx('bg-color-transparent', {
                  'trigger-SideNavMain-NavSideNavOpen': !sideNavIsOpen,
                  'trigger-SideNavMain-NavSideNavClose': sideNavIsOpen,
                  'events-all': !sectionNavIsOpen,
                  'Nav__icon--scroll': hideNav !== undefined && !hideNav,
                  'Nav__icon--scroll-active': hideNav !== undefined && hideNav,
                  'z-sidebar': !searchIsOpen,
                })}
              >
                <MenuIcon className="Nav__menu-icon" color={iconColor} />
              </Button>
            </div>
            {/* Tablet App CTA Mobile */}
            <div className="z-nav sm:none">
              {showTabletAppButton && (
                <Button
                  ariaLabel={Language.t('SideNav.MainMenu.tabletApp')}
                  to={tabletAppLink}
                  className={cx(
                    'Nav__tablet-app bg-color-transparent flex items-center events-all'
                  )}
                >
                  <span className={cx('graebenbach')}>
                    {tabletAppButtonText}
                  </span>
                </Button>
              )}
            </div>
            <div className="z-nav flex">
              {/* Tablet App CTA Tablet & Desktop */}
              {showTabletAppButton && (
                <Button
                  ariaLabel={Language.t('SideNav.MainMenu.tabletApp')}
                  to={tabletAppLink}
                  className={cx(
                    'Nav__tablet-app items-center events-all transition none sm:flex mr1_25',
                    { 'Nav__icon--scroll': scrollPosition > 0 }
                  )}
                >
                  <span className={cx('graebenbach')}>
                    {tabletAppButtonText}
                  </span>
                </Button>
              )}

              {!searchIsOpen ? (
                <Button
                  ariaLabel={Language.t('SideNav.MainMenu.searchAriaLabel')}
                  onClick={handleSearchClick}
                  className={cx(
                    'bg-color-transparent flex items-center transition',
                    {
                      'events-all': !sectionNavIsOpen,
                      'Nav__icon--scroll': scrollPosition > 0,
                    }
                  )}
                >
                  <Img
                    className="SearchBar__search-icon"
                    alt={Language.t('Global.searchIconAlt')}
                    src={
                      theme === Theme.Black && !searchIsOpen
                        ? '/assets/images/search-icon-white.svg'
                        : '/assets/icons/search-icon-black.svg'
                    }
                  />
                </Button>
              ) : (
                <Button
                  ariaLabel={Language.t('SideNav.MainMenu.searchAriaLabel')}
                  onClick={handleSearchClick}
                  className={cx('bg-color-transparent flex items-center', {
                    'events-all': !sectionNavIsOpen,
                  })}
                >
                  <Img
                    className="SearchBar__search-icon"
                    alt={Language.t('Global.searchIconAlt')}
                    src={
                      theme === Theme.Black && !searchIsOpen
                        ? '/assets/images/close-icon-white.svg'
                        : '/assets/images/close-icon.svg'
                    }
                  />
                </Button>
              )}
            </div>
          </div>

          {(children || showMiddleLogo) && (
            <div
              className={cx(
                'w100 h100 z0 relative flex justify-center items-center',
                className,
                {
                  'bg-color-off-white':
                    (!(typeof hideLogo === 'boolean') &&
                      theme === Theme.Default &&
                      !searchIsOpen) ||
                    (typeof hideLogo === 'boolean' &&
                      theme === Theme.Default &&
                      !hideLogo &&
                      !searchIsOpen),
                  'bg-color-beige':
                    !isHomeNav && theme === Theme.Beige && !searchIsOpen,
                  'bg-color-black':
                    !isHomeNav && theme === Theme.Black && !searchIsOpen,
                  'bg-color-transparent':
                    typeof hideLogo === 'boolean' && hideLogo,
                  'border-bottom-transparent':
                    scrollPosition <= 0 ||
                    (typeof hideLogo === 'boolean' && hideLogo),
                  'border-bottom-black':
                    scrollPosition > 0 && theme !== 'black',
                  'border-bottom-gray-darker':
                    scrollPosition > 0 && theme === Theme.Black,
                  'opacity-0 events-none': sectionNavIsOpen,
                  'bg-color-collections': isCollectionsNav && !searchIsOpen,
                  'bg-color-gray-lighter': searchIsOpen,
                }
              )}
            >
              {children && children}
              {showMiddleLogo && (
                <Link
                  to={RouteMap.HOME.path}
                  className={cx(
                    'Nav__logo events-all',
                    'trigger-LogoClick-MainLogo',
                    {
                      'Nav__icon--scroll':
                        hideLogoOnTop || typeof hideLogo === 'boolean',
                      'Nav__icon--scroll-active':
                        (hideLogoOnTop && scrollPosition > 0) || !hideLogo,
                    }
                  )}
                >
                  <TabletLogo className="Nav__logo-icon" color={iconColor} />
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
      <SearchBar />
      <ScrollLock />
    </>
  );
};

export default Nav;
