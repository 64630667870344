import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useUI } from 'providers/UIProvider';

interface ScrollLockProps {}

const ScrollLock: React.FC<ScrollLockProps> = (props) => {
  const { scrollLockIsActive } = useUI();

  useEffect(() => {
    if (scrollLockIsActive) {
      const targetElement = document.querySelector('body');
      if (targetElement) {
        disableBodyScroll(targetElement);
      }
    } else {
      enableBodyScroll(document.body);
    }

    return () => {
      // Clean up to re-enable scrolling when the component unmounts.
      enableBodyScroll(document.body);
    };
  }, [scrollLockIsActive]);

  return null;
};

export default ScrollLock;
